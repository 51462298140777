<template>
  <v-row>
    <v-col v-if="!canIssue" cols="12">
      <alert-ucfe-warning />
    </v-col>
    <v-col v-if="isReady" cols="12">
      <data-table
        :btn-action-path="basePath"
        :headers="headers"
        :items="items"
        :last-page="serverLastPage"
        :loading="bLocalLoading"
        :options="pagination"
        :total="serverItemsLength"
        btn-action-item-key-id="id"
        calculate-widths
        dense
        hide-btn-action-view
        is-route-name
        @delete="deleteItem"
        @update:sort-by="onSortBy"
        @update:sort-desc="onSortDesc"
        @update:options="onPagination"
        @update:page="onPage"
      >
        <template #[`body.prepend`]="{ headers }">
          <invoice-filters-row
            :filter-key="filterKey"
            :tr-headers="headers"
            :invoice-type="invoiceType"
            signed
            :provider="useProvider"
          />
        </template>

        <template #[`item.created_at`]="{ item }">
          <v-btn
            :to="{
              name: ['saved', 'debt_saved'].includes(filterKey)
                ? sViewRoute
                : sUpdateRoute,
              params: { id: item.id },
            }"
            color="primary"
            text
          >
            {{ $dayjs(item.created_at).format("L") }}
          </v-btn>
        </template>

        <template #[`item.sign_at`]="{ item }">
          <v-btn
            :to="{ name: sUpdateRoute, params: { id: item.id } }"
            color="primary"
            text
          >
            {{ $dayjs(item.sign_at).format("L") }}
            {{ $dayjs(item.sign_at).format("HH:mm:ss") }}
          </v-btn>
        </template>

        <template #[`item.invoice_type`]="{ item }">
          <invoice-alert-msg :item="item" in-dialog />
          <span v-text="getTypeName(item)" />
        </template>

        <template #[`item.total_price_value`]="{ item }">
          <price-viewer
            :currency="item.currency"
            :value="item.total_price_value"
            chip
            label
          />
          <!--
            :value="
              settings.prices_without_tax
                ? item.total_price_without_tax_value
                : item.total_price_value
            "
-->
        </template>

        <template #[`item.balance_value`]="{ item }">
          <price-viewer
            v-if="item.invoice_type && $_.endsWith(item.invoice_type.code, '1')"
            :currency="item.currency"
            :value="item.balance_value"
            chip
            label
          />
        </template>

        <template #[`item.is_cash`]="{ item }">
          <span v-text="$t(item.is_cash ? 'cash' : 'credit')" />
        </template>

        <template #[`item.order_number`]="{ item }">
          <invoice-number-btn :invoice="item" :route-name="sUpdateRoute" />
        </template>

        <template #[`item.currency`]="{ item }">
          <currency-preview :currencyId="item.currency_id" />
        </template>

        <template #[`item.customer`]="{ item }">
          <customer-preview :customer="item.customer" />
        </template>

        <template #[`item.status_dgi`]="{ item }">
          <invoice-dgi-status :invoice="item" />
        </template>

        <template #[`item.actions`]="{ item }">
          <module-actions
            :base-route-name="baseRouteName"
            :cannot-delete="item.is_signed || item.flag === 'signing'"
            :cannot-edit="item.is_signed"
            :value="item.id"
            @delete="deleteItem"
          >
            <template v-if="item.is_signed" #append-items>
              <invoice-list-action
                :item="item"
                :route-name="sUpdateRoute"
                @open:invoice-sync="onOpenInvoiceSync"
                @open:xml-preview="xmlPreview"
                @open:email-form="onOpenInvoiceEmailForm"
                @open:maillog-preview="onOpenMailLogPreview"
              />
            </template>
          </module-actions>
        </template>

        <!-- Pass on all scoped slots -->
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
          <slot :name="name" v-bind="slotProps" />
        </template>
      </data-table>
    </v-col>

    <mail-log-xml-dialog
      v-model="openMailLogPreview"
      :item="obMailLogInvoice"
    />
    <invoice-xml-dialog
      v-if="signed"
      v-model="openXmlPreview"
      :filename="filename"
      :uuid="sUuid"
    />
    <references-sync-dialog
      v-model="openReferencesSync"
      :invoice="obSelectedInvoice"
      hide-activator
    />
    <invoice-send-to-email
      v-model="openEmailSendForm"
      :invoice="obSelectedInvoice"
    />
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";
import { AppModule } from "@/store/app";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import type {
  CompanySettingsData,
  Invoice,
  InvoiceData,
} from "@planetadeleste/vue-mc-gw";
import type { DebounceFunction } from "@/plugins/helpers";
import { currencyFormat } from "@/plugins/helpers";

import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";
import AlertUcfeWarning from "@/modules/invoices/components/AlertUcfeWarning.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";
import InvoiceXmlDialog from "@/modules/invoices/components/InvoiceXmlDialog.vue";
import ReferencesSyncDialog from "@/modules/invoices/components/references/ReferencesSyncDialog.vue";
import {
  castArray,
  debounce,
  endsWith,
  filter,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
  map,
  set,
  trim,
  unset,
} from "lodash";
import { InvoiceModule } from "@/store/invoice";
import { AuthModule } from "@/store/auth";
import IconCalendarOutline from "@/components/icons/IconCalendarOutline.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import InvoiceXmlDownloadBtn from "@/modules/invoices/components/InvoiceXmlDownloadBtn.vue";
import InvoiceListAction from "@/modules/invoices/components/InvoiceListAction.vue";
import InvoiceSendToEmail from "@/modules/invoices/components/InvoiceSendToEmail.vue";
import InvoiceDgiStatus from "@/modules/invoices/components/InvoiceDgiStatus.vue";
import MailLogXmlDialog from "@/modules/invoices/components/MailLogXmlDialog.vue";
import { isNumeric } from "mathjs";
import type { InvoiceFilterData } from "@/modules/invoices/components/InvoiceFilters.vue";
import { EventBus } from "@/services/event-bus";
import InvoiceFiltersRow from "./InvoiceFiltersRow.vue";
import type { InvoiceTypeCode } from "@/types/utils";
import InvoiceAlertMsg from "@/modules/invoices/components/InvoiceAlertMsg.vue";
import { canRouteAccess } from "@/services/moduleAccess";

@Component({
  components: {
    InvoiceAlertMsg,
    MailLogXmlDialog,
    InvoiceDgiStatus,
    InvoiceSendToEmail,
    InvoiceListAction,
    InvoiceXmlDownloadBtn,
    IconEdit,
    IconCalendarOutline,
    ActiveIcon,
    AlertUcfeWarning,
    CurrencyPreview,
    CustomerPreview,
    PriceViewer,
    InvoiceNumberBtn,
    InvoiceXmlDialog,
    ReferencesSyncDialog,
    InvoiceFiltersRow,
  },
})
export default class InvoicesList extends Mixins(InvoicesMixin) {
  isList = true;
  sUuid: string | number | null = null;
  obSelectedInvoice: Invoice | null | undefined = null;
  obMailLogInvoice: InvoiceData | null = null;
  selectedInvoiceId: number | null = null;
  openReferencesSync = false;
  openEmailSendForm = false;
  openMailLogPreview = false;
  fnIndexDebounced!: DebounceFunction;

  @Prop(Boolean) readonly signed!: boolean;
  @Prop([String, Number, Array]) readonly movementType!: number | number[];
  @Prop(Array) readonly invoiceType!: InvoiceTypeCode[];
  @Prop(String) readonly baseRoute!: string;
  @Prop(String) readonly updateRoute!: string;
  @Prop({ type: Array, default: () => [] }) readonly hideHeaders!: string[];

  // @Watch("isReady", { immediate: true })
  // watchOnReady(bVal: boolean) {
  //   if (bVal) {
  //     this.onDisplay();
  //   }
  // }

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get items(): Partial<InvoiceData>[] {
    return map<Partial<InvoiceData>>(
      this.obCollection.getModelList(),
      (obData: InvoiceData) => {
        if (
          (this.signing.length && this.signing.includes(obData.id)) ||
          obData.flag === "signing"
        ) {
          set(obData, "css", "striped indigo lighten-5");
        } else if (obData.flag === "sign_error") {
          set(obData, "css", "striped red lighten-5");
          // @ts-ignore
        } else if (obData.flag === "validation_error") {
          set(obData, "css", "striped orange lighten-5");
        }

        if (
          !isNil(obData.customer) &&
          !isNil(obData.customer_firm) &&
          !isEmpty(obData.customer_firm)
        ) {
          set(obData, "customer.firm", obData.customer_firm);
        }

        return obData;
      }
    ) as unknown as Partial<InvoiceData>[];
  }

  get item(): Partial<InvoiceData> | undefined {
    if (!this.sUuid) {
      return undefined;
    }

    const obData = isNumeric(this.sUuid)
      ? { id: this.sUuid }
      : { uuid: this.sUuid };

    return find(this.items, obData) as Partial<InvoiceData> | undefined;
  }

  get filename() {
    return this.signed && this.item
      ? `${get(this.item, "cfe_name")}_${this.item.order_serial}${
          this.item.order_number
        }`
      : null;
  }

  get signing() {
    return InvoiceModule.signing || [];
  }

  get company() {
    return AppModule.company;
  }

  get settings(): Partial<CompanySettingsData> {
    return this.company.get("settings", {});
  }

  get openXmlPreview() {
    return !!this.sUuid;
  }

  set openXmlPreview(sValue: boolean) {
    if (!sValue) {
      this.sUuid = null;
    }
  }

  get basePath(): string {
    const arParts: string[] = trim(this.$route.path, "/").split("/");
    arParts.pop();

    return arParts.join("/");
  }

  get baseRouteName(): string {
    if (this.baseRoute) {
      return this.baseRoute;
    }

    const arParts: string[] = trim(this.$route.path, "/").split("/");
    arParts.pop();

    return arParts.join(".");
  }

  get sUpdateRoute(): string {
    if (this.updateRoute) {
      return this.updateRoute;
    }

    return `${this.baseRouteName}.update`;
  }

  get sViewRoute(): string {
    return `${this.baseRouteName}.view`;
  }

  get isReady() {
    return true; // !isEmpty(this.modelFilters);
  }

  get useProvider(): boolean {
    return [
      "receipt_emitted",
      "receipt_saved",
      "received",
      "received_unprocessed",
    ].includes(this.filterKey);
  }

  get arMovementTypeCode(): number[] {
    return castArray(this.movementType);
  }

  onRegisterEvents() {
    // this.bLocalLoading = true;
    this.fnIndexDebounced = debounce(this.onDisplay, 500);
    this.addEvent("app.company.selected", this.index);
    this.addEvent("invoice.signed", this.onInvoiceSigned);
    this.addEvent("invoice.unsigned", this.onInvoiceSigned);
    this.addEvent("filters.change", (sClass: string) => {
      if (sClass === this.sModelFilterKey && this.isReady) {
        this.fnIndexDebounced();
      }
    });

    this.fnIndexDebounced();
  }

  onBeforeIndex(obFilters: InvoiceFilterData) {
    // Set filters based on props
    // const obFilters: Record<string, any> = {};

    if (this.signed) {
      obFilters.signed = 1;
    } else {
      obFilters.unsigned = 1;
    }

    if (this.arMovementTypeCode.length && !obFilters.movementTypeCode) {
      obFilters.movementTypeCode = this.arMovementTypeCode;
    }

    if (this.invoiceType) {
      obFilters.invoiceTypeCode = this.invoiceType;
    }

    switch (this.filterKey) {
      case "received":
        obFilters.received = 1;

        if (canRouteAccess("invoices.received.unprocessed")) {
          obFilters.processed = 1;
        } else {
          unset(obFilters, "processed");
        }

        unset(obFilters, "unsigned");
        unset(obFilters, "signed");
        break;

      case "received_unprocessed":
        obFilters.received = 1;
        obFilters.notProcessed = 1;
        unset(obFilters, "unsigned");
        unset(obFilters, "signed");
        break;

      case "receipt_emitted":
        obFilters.movementTypeCode = [9];
        unset(obFilters, "signed");
        unset(obFilters, "unsigned");
        break;

      case "receipt_saved":
        obFilters.movementTypeCode = [9];
        unset(obFilters, "signed");
        obFilters.unsigned = 1;
        break;

      case "debt_emitted":
        obFilters.movementTypeCode = [7, 14, 15];
        unset(obFilters, "unsigned");
        obFilters.signed = 1;
        break;

      case "debt_saved":
        obFilters.movementTypeCode = [7, 14, 15];
        unset(obFilters, "signed");
        obFilters.unsigned = 1;
        break;

      case "saved":
        unset(obFilters, "signed");
        obFilters.unsigned = 1;
        break;
    }
  }

  onMounted() {
    const sCustomerLabel = this.useProvider ? "provider" : "customer";
    let arHeaders: DataTableHeader[] = [
      { text: "invoice.type", value: "invoice_type", sortable: false },
      { text: "invoice.payment.type", value: "is_cash", sortable: false },
      { text: "date", value: "created_at", sortable: true },
      { text: sCustomerLabel, value: "customer", sortable: true },
      { text: "currency", value: "currency", sortable: true },
      {
        text: `invoice.total.price`,
        value: "total_price_value",
        sortable: false,
      },
    ];

    if (this.signed) {
      const obItemHeaderOrderNumber = {
        text: "invoice.dgi.number",
        value: "order_number",
        sortable: true,
      };

      arHeaders.splice(2, 0, obItemHeaderOrderNumber);

      if (!this.invoiceType || !castArray(this.invoiceType).includes(701)) {
        const obItemHeaderStatusDgi = {
          text: "invoice.dgi.status",
          value: "status_dgi",
          sortable: false,
        };

        arHeaders.splice(7, 0, obItemHeaderStatusDgi);
      }

      // const obItemHeaderSignedAt = {
      //   text: "date.sign.at",
      //   value: "sign_at",
      //   sortable: true,
      // };
    }

    if (this.hideHeaders.length) {
      arHeaders = filter(
        arHeaders,
        (obHeader) => !this.hideHeaders.includes(obHeader.value)
      );
    }

    EventBus.emit("invoices.list.apply.headers", arHeaders);

    this.setDTHeaders(arHeaders);
    this.bLocalLoading = false;
  }

  onDisplay() {
    // this.bLocalLoading = false;
    // console.log(this.modelFilters);
    this.index();
  }

  priceFormat(obInvoiceData: InvoiceData) {
    const sCurrencyCode = obInvoiceData.currency
      ? obInvoiceData.currency.code
      : "UYU";
    const fValue = get(obInvoiceData, "total_price_value");

    return currencyFormat(fValue, sCurrencyCode);
  }

  printInvoice(obInvoiceData: InvoiceData) {
    return `/invoices/print/${get(obInvoiceData, "uuid")}`;
  }

  getTypeName(obInvoiceData: InvoiceData) {
    let sName = get(obInvoiceData, "cfe_name", "");

    if (isNil(sName) || isEmpty(sName)) {
      sName = get(obInvoiceData, "invoice_type.name", "");
    }

    return sName;
  }

  canCopy(obInvoiceData: InvoiceData) {
    const sCode = get(obInvoiceData, "invoice_type.code");

    return !isNil(sCode) && endsWith(sCode, "1");
  }

  canPay(obInvoiceData: InvoiceData) {
    return (
      get(obInvoiceData, "balance_value", 0) > 0 && this.canCopy(obInvoiceData)
    );
  }

  xmlPreview(sValue: string) {
    this.sUuid = sValue;
  }

  onOpenInvoiceSync(id: number) {
    this.obSelectedInvoice = this.obCollection.find({ id });
    if (this.obSelectedInvoice) {
      this.openEmailSendForm = false;
      this.openReferencesSync = true;
    }
  }

  onOpenInvoiceEmailForm(id: number) {
    this.obSelectedInvoice = this.obCollection.find({ id });

    if (this.obSelectedInvoice) {
      this.openReferencesSync = false;
      this.openEmailSendForm = true;
    }
  }

  onOpenMailLogPreview(obData: InvoiceData) {
    this.obMailLogInvoice = obData;
    this.openMailLogPreview = true;
  }

  onInvoiceSigned(sValue: number) {
    forEach(this.items, (obItem) => {
      if (obItem.id === sValue) {
        unset(obItem, "css");
        this.emit("reload.index");
      }
    });
  }
}
</script>
